<template>
  <div>
    <el-dialog
      title="Export Orders"
      :visible.sync="setShow"
      @close="closeEvent"
      width="33%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form :model="form" label-position="top" ref="form">
          <el-row>
            <el-col :span="24">
              <el-form-item
                label="Period"
                prop="period"
                :rules="validateField()"
              >
                <el-select v-model="form.period" placeholder="Select period">
                  <el-option
                    v-for="(value, key, i) in periods"
                    :key="i"
                    :label="value"
                    :value="key"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <template v-if="form.period === 'custom'">
            <el-row type="flex">
              <el-col :span="24">
                <el-radio-group
                  v-model="customType"
                  class="eden-switch-radio small"
                >
                  <el-radio-button label="specific"
                    >Specific day</el-radio-button
                  >
                  <el-radio-button label="multiple"
                    >Multiple days</el-radio-button
                  >
                </el-radio-group>
              </el-col>
            </el-row>
            <el-row v-if="customType === 'specific'">
              <el-col :span="24">
                <el-form-item
                  label="Date"
                  prop="one_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.one_date"
                    :clearable="false"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    prefix-icon="eden-icon--calendar"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-else type="flex" class="is-flex-wrap" :gutter="15">
              <el-col :md="12" :lg="12">
                <el-form-item
                  label="From"
                  prop="from_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.from_date"
                    :clearable="false"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    prefix-icon="eden-icon--calendar"
                  />
                </el-form-item>
              </el-col>
              <el-col :md="12" :lg="12">
                <el-form-item
                  label="To"
                  prop="to_date"
                  :rules="validateField()"
                >
                  <el-date-picker
                    v-model="form.to_date"
                    :clearable="false"
                    type="date"
                    format="dd MMM, yyyy"
                    value-format="yyyy-MM-dd"
                    prefix-icon="eden-icon--calendar"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </template>
          <el-row>
            <el-col :span="24">
              <el-form-item label="Select file format">
                <el-radio-group v-model="form.format">
                  <el-radio label="pdf">PDF</el-radio>
                  <el-radio label="csv">CSV</el-radio>
                  <el-radio label="xls">Excel</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button plain @click="setShow = false">Cancel</el-button>
          <el-button
            type="primary"
            :loading="loading || exporting"
            @click="exportOrders"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
import orders from "@/requests/orders";

export default {
  name: "OrdersExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        period: "",
        from_date: "",
        to_date: "",
        one_date: "",
        format: "pdf",
      },
      customType: "specific",
      periods: {
        nextweek: "Next week",
        thisweek: "This week",
        nexttwodays: "Next two days",
        today: "Today",
        yesterday: "Yesterday",
        lastweek: "Last week",
        custom: "Custom",
      },
      exportData: {},
      exporting: false,
      loading: false,
      downloaded: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
      this.exporting = false;
    },
    exportOrders() {
      console.log(this.form.format)
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.loading = true;
        let payload;

        if (this.form.period === "custom" && this.customType === "specific") {
          payload = {
            one_date: this.form.one_date,
          };
        } else {
          payload = {
            from_date: this.form.from_date,
            to_date: this.form.to_date,
          };
        }

        orders
          .export(this.form.period, payload)
          .then((response) => {
            if (response.data.status) {
              this.formattedDataForDownload(response.data.data);
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    formattedDataForDownload(data) {
      console.log(data);
      const title = `Orders`;

      let headers = {
        id: "Order Id",
        customer: "Customer",
        service: "Service",
        gardener: "Gardener",
        pickup: "Pickup Date",
        delivery: "Delivery Date",
        status: "Status",
        order_type: "Order Type",
      };

      let orders = [];

      data.forEach((order) => {
        const id = order.order_id;
        const name = order.customer_name
          ? this.formatName(order.customer_name)
          : "-";
        const email = order.customer_email
          ? order.customer_email.toLowerCase()
          : "-";
        const customer = `${name}\n${email}`;
        const gardener = order.gardener_for_customer ?? "-";
        const service = order.service;
        const pickup =
          order.service.toLowerCase() === "laundry"
            ? this.formatDate(order.pickup_from_customer, "do m, y")
            : "Delivery only";
        const delivery = this.formatDate(order.deliver_to_customer, "do m, y");
        const status =
          this.formatText(order.completed_status, "do m, y") || "-";
        const order_type = order.is_one_time_order
          ? "One-time"
          : "Subscription";

        orders.push({
          id,
          customer,
          gardener,
          service,
          pickup,
          delivery,
          status,
          order_type,
        });
      });

      this.exportData = {
        title,
        headers,
        data: orders,
        fileName: "Orders",
        titleMargin: 44,
        format: this.form.format,
      };

      this.exporting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
